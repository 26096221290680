<template>
  <div data-testid="navbar-user-component" ref="loginModal">
    <div class="relative flex">
      <div class="flex items-center">
        <div
          class="cursor-pointer z-20 relative flex items-center group"
          :class="{ 'flex-col': false }"
          @click="toggle"
        >
          <NuxtImg
            v-if="userStore.hasImage"
            :src="`https://legacy.altinget.dk/images/useravatars/${userStore.user?.avatar}`"
            class="w-6 h-6 md:mr-1 inline"
            width="24"
            height="24"
            :class="[
              { 'opacity-100': isLoginPopupOpen },
              userStore.user?.avatar
                ? 'rounded-full'
                : 'opacity-50 group-hover:opacity-100 transition-opacity duration-100',
            ]"
          />
          <img
            v-else
            :src="ProfileFallback"
            alt="Profile"
            class="w-6 h-6 md:mr-1 inline"
            :class="!userStore.user ? ['md:hidden'] : []"
          />
          <span
            class="md:inline-block list-title-xxxs !font-medium select-none hidden opacity-50 group-hover:opacity-100 transition-opacity duration-100"
            :class="[
              {
                'opacity-100': isLoginPopupOpen,
                'clarity-mask': userStore.user,
              },
            ]"
          >
            <span
              data-testid="user-logged-in"
              v-if="userStore.user && userStore.isLoggedIn"
              >{{
                userStore.hasLoginType('IP')
                  ? userStore.user.name
                  : $t('MyProfile')
              }}</span
            >
            <span data-testid="nav-login-button" v-else>{{ $t('Login') }}</span>
            <img
              v-if="!userStore.user"
              src="@/assets/icons/icon-action-login.svg?url"
              class="w-4 h-4 ml-2 inline-block"
              alt="Login"
            /><img
              v-if="userStore.user"
              src="@/assets/icons/dropdown.svg?url"
              alt="Dropdown"
              class="w-4 h-4 ml-1 inline opacity-50 group-hover:opacity-100 transition-opacity duration-100"
              :class="[{ 'opacity-100': isLoginPopupOpen }]"
            />
          </span>
        </div>
        <div
          v-if="isLoginPopupOpen"
          data-testid="navbar-login-popup"
          class="flex flex-col top-10 mt-2 right-0 grid-cols-5 z-10 min-w-48 absolute bg-white shadow rounded-lg p-2"
        >
          <CommonLogin
            v-if="!userStore.user"
            ref="login"
            :try-login="true"
            v-model:email="input.email"
            v-model:password="input.password"
            @loggedIn="hide"
          />
          <div v-else data-testid="navbar-mit-altinget">
            <NuxtLink
              v-if="userStore.hasLoginType('UsernamePassword', 'Sso')"
              :to="{ name: accountItem.route }"
              @click.native="toggle"
              class="label-s text-gray-500 uppercase px-2 pb-4 block"
            >
              {{ $t(accountItem.label) }}
            </NuxtLink>
            <ul class="list-title-xxxs !font-medium">
              <template
                v-if="userStore.hasLoginType('UsernamePassword', 'Sso')"
              >
                <li
                  v-for="item in menuItems"
                  class="px-2 py-3 rounded hover:bg-gray-100 transition-bg duration-100"
                >
                  <NuxtLink :to="item.route" @click.native="toggle">
                    <CommonFontAwesomeIcon
                      class="text-sm text-gray-700 w-3.5 mr-1.5"
                      :icon="item.icon"
                    />
                    {{ $t(item.label) }}
                  </NuxtLink>
                </li>
                <CommonDivider customMargin="my-2" />
              </template>
              <li
                class="flex items-center cursor-pointer px-2 py-3 rounded hover:bg-gray-100 transition-bg duration-100"
                @click="logout"
              >
                <img
                  src="~/assets/icons/icon-action-logout.svg?url"
                  class="w-4 h-4 mr-2.5 inline-block"
                />
                {{ $t('Logout') }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <NuxtLink
        id="UserCreateProfile"
        :to="
          true ? { name: 'pricing' } : { name: 'subscription' }
        "
        v-if="showSubscriptionButton"
        class="button-l rounded-full px-4 border h-[36px] bg-blue text-white border-none whitespace-nowrap cursor-pointer items-center transition-colors inline-flex duration-100 select-none shrink-0 justify-center ml-5"
        :class="false ? 'hidden lg:flex' : 'md:flex'"
      >
        {{ $t('CreateProfile') }}
      </NuxtLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'
import ProfileFallback from '@/assets/icons/profile.svg?url'
import { InputFieldInjectionKey } from '~/typesManual/InjectKeys'

const { menuItems } = await useAccountMenu()
const dataLayer = useDataLayer()
const userStore = useUserStore()

const emit = defineEmits<{
  (e: 'hideMenu'): void
}>()
const route = useRoute()

const loginInputField = ref<HTMLInputElement>()
// Provide a function to Input.vue so we can get the input field reference.
// With this we can focus the email field when opening the modal
provide(InputFieldInjectionKey, (inputField: HTMLInputElement) => {
  loginInputField.value = inputField
})
const isLoginPopupOpen = ref(false)
const input = ref({
  email: '',
  password: '',
})
const accountItem = {
  label: false ? 'MyMandagMorgen' : 'MyAltinget',
  route: 'account',
}

function toggle() {
  isLoginPopupOpen.value = !isLoginPopupOpen.value
  emit('hideMenu')
  if (isLoginPopupOpen.value && loginInputField?.value) {
    loginInputField.value.focus()
  }
}
function hide() {
  isLoginPopupOpen.value = false
}
async function logout() {
  if (!userStore.user) {
    return
  }

  if (userStore.hasLoginType('UsernamePassword')) {
    const protectedPathListRegex =
      useRuntimeConfig().public.protectedPathList.map((item) => {
        if (item[item.length - 1] === '*') {
          return RegExp(`^${item.replaceAll('*', '.*')}$`)
        }
        return RegExp(`^${item}$`)
      })

    for (let i = 0; i < protectedPathListRegex.length; i++) {
      const item = protectedPathListRegex[i]

      if (item.test(route.fullPath)) {
        await userStore.logout()
        await navigateTo({ name: 'frontpage' })
        return
      }
    }

    dataLayer.basic.trackUserLogout()
    await userStore.logout()
    await navigateTo({ name: route.name, params: {} })
    return
  } else {
    await userStore.logout()
  }
}
const loginModal = ref<HTMLDivElement>()
onClickOutside(loginModal, () => {
  hide()
})

const isSubscriptionRoute = computed(() => {
  return route.name === 'subscription'
})
const showSubscriptionButton = computed(() => {
  if (false) {
    return !userStore.user && !isSubscriptionRoute.value
  }
  return !userStore.user
})
</script>
