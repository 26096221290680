<template>
  <div v-show="true || empty">
    <CommonListLabel
      v-if="placement === 'side'"
      :label="label"
      :link="`${currentPaperUrl}/${link ? link : $t('Routes.Names')}`"
    />
    <CommonDivider
      :dark="false"
      :class="placement === 'side' ? '' : 'mt-0 md:mt-3 mb-3 md:mb-5'"
    />

    <ul>
      <template v-for="(item, index) in articlesWithType" :key="index">
        <li>
          <Transition name="skeleton" mode="out-in">
            <NuxtLink
              v-if="typeof articlesWithType === 'object'"
              :to="{
                name: indexStore.currentPaperSlug
                  ? link
                    ? 'paper_jobchange'
                    : 'paper_names'
                  : link
                  ? 'jobchange'
                  : 'names',
                params: {
                  id: item.UrlKey,
                  paper: indexStore.currentPaperSlug,
                },
              }"
              class="flex"
            >
              <CommonImageLoader
                v-if="
                  placement === 'side' &&
                  item.Persons[0] &&
                  item.Persons[0].Image
                "
                :height="64"
                :width="64"
                class="w-12 h-12 rounded-full shrink-0 grow-0 mr-3 overflow-hidden"
                :src="
                  item.Persons[0].MasterId && item.Persons[0].MasterId !== 0
                    ? 'https://legacy.altinget.dk/images/person/' +
                      item.Persons[0].MasterId +
                      '/' +
                      item.Persons[0].Image
                    : 'https://' +
                      config.public.site.legacyurl +
                      '/images/person/' +
                      item.Persons[0].RecordId +
                      '/' +
                      item.Persons[0].Image
                "
              />
              <div>
                <div
                  v-if="item.Persons[0]"
                  class="mb-1 flex"
                  :class="
                    placement === 'side'
                      ? 'list-label !font-medium'
                      : placement === 'main' && index === 0
                      ? 'list-title-l !font-light'
                      : placement === 'main' && index === 1
                      ? 'list-title-m !font-light'
                      : 'list-title-s !font-light items-center'
                  "
                >
                  <span class="text-black text-opacity-50">Om </span>&nbsp;
                  <NuxtImg
                    v-if="
                      placement === 'main' &&
                      item.Persons[0] &&
                      item.Persons[0].Image
                    "
                    class="rounded-full shrink-0 grow-0 mr-1 bg-center inline-block bg-cover"
                    :src="
                      item.Persons[0].MasterId && item.Persons[0].MasterId !== 0
                        ? 'https://legacy.altinget.dk/images/person/' +
                          item.Persons[0].MasterId +
                          '/' +
                          item.Persons[0].Image
                        : 'https://' +
                          config.public.site.legacyurl +
                          '/images/person/' +
                          item.Persons[0].RecordId +
                          '/' +
                          item.Persons[0].Image
                    "
                    :class="
                      index === 0
                        ? 'w-9 h-9'
                        : index === 1
                        ? 'w-8 h-8'
                        : 'w-6 h-6'
                    "
                    width="64"
                    height="64"
                  />
                  <span class="text-black text-opacity-50">{{
                    item.Persons[0] ? item.Persons[0].Name : ''
                  }}</span>
                </div>
                <div
                  class="text-black list-title-xxxs"
                  :class="
                    placement === 'side'
                      ? 'list-title-xxxs'
                      : placement === 'main' && index === 0
                      ? 'list-title-l'
                      : placement === 'main' && index === 1
                      ? 'list-title-m'
                      : 'list-title-s'
                  "
                >
                  {{ item.HeadLine }}
                </div>
                <div
                  v-if="placement === 'main'"
                  class="mt-3 article-label !font-medium opacity-50 flex items-center"
                >
                  <template
                    v-if="
                      item.Papers &&
                      item.Papers.find(
                        (paper) =>
                          paper.Paper === indexStore.currentPaper?.RecordId
                      )
                    "
                  >
                    {{ getTimeAndDate(item) }}
                  </template>
                </div>
              </div>
            </NuxtLink>
            <ContentLoader
              v-else
              :key="'li' + index"
              :height="placement === 'main' ? 44 : 34"
              :width="placement === 'main' ? 400 : 200"
              :primary-color="indexStore.skeleton.primaryColor"
              :secondary-color="indexStore.skeleton.secondaryColor"
            >
              <template v-if="placement === 'main'">
                <circle cx="7" cy="7" r="7" />
                <rect x="18" y="2" rx="3" ry="3" width="160" height="10" />
                <rect x="0" y="18" rx="3" ry="3" width="380" height="10" />
                <rect x="0" y="34" rx="3" ry="3" width="320" height="10" />
              </template>
              <template v-else>
                <circle cx="17" cy="17" r="17" />
                <rect x="40" y="0" rx="3" ry="3" width="160" height="10" />
                <rect x="40" y="16" rx="3" ry="3" width="150" height="10" />
              </template>
            </ContentLoader>
          </Transition>
        </li>
        <CommonDivider :class="placement === 'side' ? '' : 'my-3 md:my-5'" />
      </template>
    </ul>
    <div v-if="showMore" class="flex justify-center">
      <CommonButton @click.native="loadMore">
        {{ $t('ShowMore') }}
      </CommonButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ContentLoader } from 'vue-content-loader'
import type { ContentArticleWithPerson } from '~/api/content'

const nuxtApp = useNuxtApp()
const config = useRuntimeConfig()

const indexStore = useIndexStore()

const props = withDefaults(
  defineProps<{
    count?: number
    label?: string
    link?: string
    offset?: number
    page?: number
    placement?: string
    showMore?: boolean
    type?: number
  }>(),
  {
    count: 5,
    label: 'NamesNews',
    page: 1,
    placement: 'side',
    showMore: false,
  }
)

const currentPage = ref(0)

const currentPaperUrl = computed(() => {
  if (!indexStore.currentPaper) return ''
  if (indexStore.currentPaper.RecordId === 1) return '' // Paper 1 is the front page
  return `/${indexStore.currentPaper.Url}`
})

const cacheKey = computed(
  () =>
    `names-${indexStore.currentPaper?.RecordId}-${props.count}-${currentPage.value}-${props.type}`
)
const { data: articlesWithType, refresh } = await useAsyncData(
  cacheKey.value,
  async () => {
    const names = await nuxtApp.$api.content.names({
      paper: indexStore.currentPaper ? indexStore.currentPaper.RecordId : 1,
      type: 'names',
      id: false ? 93 : 135,
      count: props.count,
      page: currentPage.value,
      subtype: props.type,
      offset: props.offset,
    })
    if (currentPage.value > 0 && articlesWithType.value) {
      const previousContentArticle: ContentArticleWithPerson[] = [
        ...articlesWithType.value,
      ]
      return [...previousContentArticle, ...names]
    }

    return names
  },
  { default: () => [] as ContentArticleWithPerson[] }
)

const empty = computed(() => articlesWithType.value?.length === 0)

const loadMore = () => {
  currentPage.value += 1
  refresh()
}
const getTimeAndDate = (item: ContentArticleWithPerson) => {
  const date = new Date(
    item.Papers?.find(
      (paper) => paper.Paper === indexStore.currentPaper?.RecordId
    )?.PublishingDate ?? 0
  )
  return `${formatDate(date)} kl ${formatTime(date)}`
}
</script>
